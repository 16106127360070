// remove attr from object with null values

import store from './store'
import { v4 as uuidv4 } from 'uuid';



export const generateUUID = function () {
    return uuidv4()
}

export const logInfo = function (msg) {
    store.dispatch('util/log',{level:'s',msg})
}

export const logWarning = function (msg) {
    store.dispatch('util/log',{level:'w',msg})
}

export const logError = function (msg) {
    store.dispatch('util/log',{level:'e',msg})
}

export const ellipsis = function (txt, length) {
    if(!txt) return '-'
    return txt.length > length ? txt.substring(0, length - 1) + '...' : txt;
}


export const arrayMove = (arr, fromIndex, toIndex) => {
    const ret = arr
    const element = ret[fromIndex];
    ret.splice(fromIndex, 1);
    ret.splice(toIndex, 0, element);
    return ret
}

export const listToDict = (lst, propSrc, propDest = null) => {
    return (lst || []).reduce((prev, el)=>{
        prev[el[propSrc]] = propDest ? el[propDest] : el
        return prev
    },{})
}


export const requiredRules = [v => !(v == null) || "Required"]

export const positiveNumberRules = [
    v => !v || v >= 0 || "Este campo debe estar por encima de 0"
]

export const objectToQueryParamsFilterModel = (obj) => {
    const ret = []
    for (const [key, value] of Object.entries(obj || {})) {
        if (value !== "" && value !== null) {
            if (value.length > 0) {
                let filterValue = {};
                value.forEach(el => (filterValue = { ...filterValue, [el]: el }));
                ret.push({
                    columnField: key,
                    filterValue: filterValue
                });
            }
        }
    }
    return ret
}
